<template>

  <nav >
    <v-app-bar app color="#224E5F" dark >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      {{ username }}
      <v-spacer></v-spacer>
      <v-btn text @click="cerrarSesion">
        <span class="mr-2">Cerrar Sesión</span>
        <v-icon>fas fa-power-off</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer color="#224E5F" v-model="drawer" 
    dark
    app 
    :temporary="temporary"
    :permanent="permanent"
    :src="getImage"
    >
<!--  -->
           <v-list-item two-line>
            <v-list-item-avatar>
              <img src="@/assets/logo.png">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Infocafe</v-list-item-title>
              <v-list-item-subtitle>Comercialización</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <template v-for="item in items">
          
          <v-list shaped 
          v-if="item.children"
          :key="item.title"
          >
            
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            
            <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            link
            :to="child.to"
          >
            <v-list-item-title v-text="child.title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
          </v-list-group>
          </v-list>

          <!-- ELSE -->
          <v-list-item
            v-else
            :key="item.title"
            :to="item.to"
            link
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
        </template>


      <!-- </v-list> -->

      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn block>
            Cerrar Sesión
          </v-btn>
        </div>
      </template> -->

    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapActions, mapState } from "vuex";
import bg from '../assets/fondomenu1.jpg';
export default {
  name: "navbar",
  methods: {
    ...mapActions(["cerrarSesion"])
  },
  computed: {
    ...mapState(["userType"]),
    items() {
        return this.muestreo;
        // return this.type == 1 ? this.muestreo : this.catacion;
    },
    temporary() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
    },
    permanent() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return false
        case 'sm': return false
        case 'md': return true
        case 'lg': return true
        case 'xl': return true
      }
    },
    getImage() {
     return bg;
    }
  },
  data() {
    return {
      username: localStorage.username || '',
      drawer: false,
      mini: false,
      group: null,
      type: null,
      muestreo: [
        { title: "Bancos", icon: "fas fa-university", to:{name: 'banks'} },
        { title: "Categorias", icon: "fas fa-stream", to:{name: 'categories'} },
        // { title: "Conductores", icon: "fas fa-truck-moving", to:{name: 'drivers'} },
        { title: "Gastos", icon: "fas fa-money-bill-alt", to:{name: 'expenses'} },
        { title: "Transferencias", icon: "fas fa-money-check-alt", to:{name: 'reportTransferences'} },
        // { title: "Completar Pagos", icon: "fas fa-money-check-alt", to:{name: 'completePays'} },
        { title: "Café en Depósito", icon: "fas fa-coffee", to:{name: 'deposits'} },
        { title: "Recepción de Café", icon: "fas fa-sign-in-alt", to:{name: 'reception'} },
        { title: "Pago a Productores", icon: "fas fa-shopping-cart", to:{name: 'pays'} },
        { title: "Ventas", icon: "fas fa-money-bill-wave-alt", to:{name: 'sales'} },
        { title: "Anticipos", icon: "fas fa-coins", to:{name: 'advances'} },
        { title: "Productores", icon: "fas fa-address-card", to:{name: 'providers'} },
        { title: "Cálculo de Retenciones", icon: "fas fa-calculator", to:{name: 'calculate'} },
        { title: "Constancia", icon: "fas fa-calculator", to:{name: 'constance'} },
        { title: "Retención Mayoristas", icon: "fas fa-address-book", to:{name: 'calculatePartner'} },
        { title: "Reporte Retenciones", icon: "fas fa-file-alt", to:{name: 'reportRetentions'} },
        // { title: "Reporte de Gastos", icon: "fas fa-search-dollar", to:{name: 'reportExpenses'} },
        // { 
        //   title: "Reportes", icon: "fas fa-file-alt",
        //   children: [
        //   ],
        
        // },
        // { title: "Compras", icon: "fas fa-calculator", to:{name: 'calculate'} },
        
      ],
      right: true
    };
  },

  watch: {
      group () {
        this.drawer = false
      },
    },
  created() {
    this.type = localStorage.userType;
  }
};
</script>

<style>
 /* v-list-group__header v-list-item v-list-item--active v-list-item--link theme--dark */

/* v-list-item--active v-list-item v-list-item--link theme--dark {
  color: red;
} */
.v-application a {
    color: #ffffff !important;
}

.v-list-group.v-list-group--active.primary--text {
   color: #ffffff !important;
   caret-color: #8D4925 !important;
}

.v-list-group__items {
  padding-left: 16px
}
</style>